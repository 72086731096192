var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    { staticClass: "custom-times p-0" },
    [
      _c(
        "CCard",
        { staticClass: "add-custom-times shadow" },
        [
          _c(
            "CCardHeader",
            {
              staticClass:
                "d-flex align-items-center justify-content-between border-0 text-success px-0",
              attrs: { role: "button" },
              on: {
                click: () => this.$store.commit("toggle", "addCustomTimes"),
              },
            },
            [
              _c(
                "h5",
                { staticClass: "d-flex flex-gap-1 align-items-center m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-${
                        _vm.$store.state.addCustomTimes
                          ? "chevron-bottom"
                          : "plus"
                      }`,
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.form.id ? "Edit" : "Add") +
                      " custom hours "
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.addCustomTimes } },
            [
              _c(
                "CCardBody",
                { staticClass: "p-2" },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "form-group date-picker" },
                      [
                        _c("label", { staticClass: "d-block" }, [
                          _vm._v("Day / Holiday:"),
                        ]),
                        _c("v-date-picker", {
                          attrs: {
                            mode: "date",
                            "disabled-dates": {
                              end: new Date().setDate(new Date().getDate() - 1),
                            },
                            attributes: _vm.datePickerAttributes,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ inputValue, inputEvents }) {
                                return [
                                  _c(
                                    "input",
                                    _vm._g(
                                      {
                                        staticClass:
                                          "form-control border px-2 py-1",
                                        class: {
                                          "is-invalid":
                                            _vm.form.custom_date &&
                                            !_vm.isCustomDateValid,
                                        },
                                        domProps: { value: inputValue },
                                      },
                                      inputEvents
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.pickedDate,
                            callback: function ($$v) {
                              _vm.pickedDate = $$v
                            },
                            expression: "pickedDate",
                          },
                        }),
                        _c("small", { staticClass: "text-info text-info" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.findHolidayByDate(_vm.pickedDate)
                                  ?.holiday_name?.name
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "CCollapse",
                    { attrs: { show: _vm.isCustomDateValid } },
                    [
                      _c(
                        "CButton",
                        {
                          staticClass: "px-0",
                          attrs: { color: "link" },
                          on: { click: _vm.applyPickupToDelivery },
                        },
                        [
                          _vm.isApplied
                            ? _c(
                                "span",
                                [
                                  _c("CIcon", {
                                    staticClass: "mr-1",
                                    attrs: { name: "cil-check-alt" },
                                  }),
                                  _vm._v("Applied! "),
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _c("CIcon", {
                                    staticClass: "mr-1",
                                    attrs: { name: "cil-copy" },
                                  }),
                                  _vm._v("Apply Pickup to Delivery "),
                                ],
                                1
                              ),
                        ]
                      ),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Pickup",
                          time: _vm.$v.form.pickup_times.$model,
                        },
                        on: {
                          isValid: (value) => (_vm.form.isValid = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.pickup_times,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Delivery",
                          time: _vm.$v.form.delivery_times.$model,
                        },
                        on: {
                          isValid: (value) => (_vm.form.isValid = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.delivery_times,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex mt-3" },
                    [
                      _c(
                        "CButton",
                        {
                          staticClass: "pl-0",
                          attrs: { color: "link" },
                          on: { click: _vm.cancelEdit },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "CButton",
                        {
                          attrs: {
                            color: "success",
                            shape: "pill",
                            disabled: !_vm.isCustomDateValid,
                          },
                          on: { click: _vm.confirm },
                        },
                        [_vm._v("Confirm")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("CElementCover", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.submitted,
                expression: "submitted",
              },
            ],
            attrs: { opacity: 0.4 },
          }),
        ],
        1
      ),
      _c("CDataTable", {
        attrs: {
          striped: "",
          hover: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          "column-filter": { external: true, lazy: true },
          sorter: { external: true, resetable: true },
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No custom opening times found!",
          },
        },
        on: { "update:sorter-value": _vm.sorterValue },
        scopedSlots: _vm._u([
          {
            key: "custom_date-filter",
            fn: function () {
              return [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customDate,
                      expression: "customDate",
                    },
                  ],
                  attrs: { type: "date" },
                  domProps: { value: _vm.customDate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.customDate = $event.target.value
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "custom_date",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c("strong", [_vm._v(_vm._s(item.custom_date))]),
                  item.holiday
                    ? _c("cite", { staticClass: "text-info ml-3" }, [
                        _vm._v(" " + _vm._s(item.holiday?.holiday_name?.name)),
                      ])
                    : _vm._e(),
                ]),
              ]
            },
          },
          {
            key: "type",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _vm._v(" " + _vm._s(_vm._f("ucfirst")(item.type)) + " "),
                ]),
              ]
            },
          },
          {
            key: "pickup_times",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    item.pickup_times
                      ? _vm._l(
                          item.pickup_times.split(","),
                          function (times, index) {
                            return _c(
                              "strong",
                              { staticClass: "border border-dark p-1 mr-1" },
                              [_vm._v(" " + _vm._s(times) + " ")]
                            )
                          }
                        )
                      : _c(
                          "span",
                          {
                            staticClass:
                              "closed d-flex align-items-center flex-gap-1",
                          },
                          [
                            _c("span", { staticClass: "dot bg-danger" }),
                            _vm._v(" Closed all day "),
                          ]
                        ),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "delivery_times",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    item.delivery_times
                      ? _vm._l(
                          item.delivery_times.split(","),
                          function (times, index) {
                            return _c(
                              "strong",
                              { staticClass: "border border-dark p-1 mr-1" },
                              [_vm._v(" " + _vm._s(times) + " ")]
                            )
                          }
                        )
                      : _c(
                          "span",
                          {
                            staticClass:
                              "closed d-flex align-items-center flex-gap-1",
                          },
                          [
                            _c("span", { staticClass: "dot bg-danger" }),
                            _vm._v(" Closed all day "),
                          ]
                        ),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item, index }) {
              return [
                _c(
                  "td",
                  { staticClass: "py-2 text-center" },
                  [
                    new Date(item.custom_date) >= new Date()
                      ? _c(
                          "CButton",
                          {
                            attrs: {
                              size: "sm",
                              color: "info",
                              variant: "ghost",
                              shape: "pill",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editItem(item, index)
                              },
                            },
                          },
                          [_c("CIcon", { attrs: { name: "cil-align-left" } })],
                          1
                        )
                      : _vm._e(),
                    item.deleting
                      ? _c("CSpinner", {
                          attrs: { size: "sm", color: "danger" },
                        })
                      : _c(
                          "CButton",
                          {
                            attrs: {
                              size: "sm",
                              color: "danger",
                              variant: "ghost",
                              shape: "pill",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(item, index)
                              },
                            },
                          },
                          [_c("CIcon", { attrs: { name: "cil-trash" } })],
                          1
                        ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.total))]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }